<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline"
          >{{ selectedItem ? $tc("dialog.edit") : $tc("dialog.upload") }}
          {{ itemName }}</span
        >
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-5">
          <v-row>
            <v-col v-if="!selectedItem" class="mt-3" cols="12" sm="6" md="6">
              <div class="image-container">
                <img
                  id="image"
                  v-if="mediaType == 'image'"
                  :style="
                    !fileSrc
                      ? 'border:1px solid #52525242; min-width: 100%; min-height: 235px; max-height: 235px; object-fit:cover;filter: grayscale(100%);'
                      : ''
                  "
                  class="preview"
                  :src="fileSrc || defaultImage"
                  alt=""
                />

                <video autoplay v-else muted id="video" controls></video>

                <div style="width: 100%">
                  <i @click="filePicker" class="fas fa-plus add"></i>
                </div>
              </div>

              <v-progress-linear
                :value="progress"
                color="progress-bar"
                style="border-radius: 5px"
                class="mt-3"
                height="30"
              >
                <strong>{{ Math.ceil(progress) }}%</strong>
              </v-progress-linear>
            </v-col>
            <v-col
              cols="12"
              :sm="selectedItem ? 12 : 6"
              :md="selectedItem ? 12 : 6"
              class="mt-3"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="item.name"
                    :label="$tc('dialog.name')"
                    clearable
                    v-on:keyup.enter="selectedItem ? update() : save()"
                    :loading="loading"
                    hide-details
                    rounded
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    rounded
                    hide-details
                    :loading="loading"
                    v-model="item.description"
                    rows="3"
                    :label="$tc('dialog.description')"
                    filled
                  >
                  </v-textarea>
                </v-col>

                <v-file-input
                  :accept="
                    mediaType == 'image'
                      ? 'image/png, image/jpeg, image/gif'
                      : 'video/*'
                  "
                  show-size
                  style="display: none"
                  id="file"
                  counter
                  :disabled="loading"
                  @change="selectedFile"
                  label="Seleccionar archivo"
                  rounded
                  filled
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mt-5">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="selectedItem ? update() : save()"
                  color="buttons"
                  >{{ $tc("button.save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import VideoToThumb from "video-thumb-generator";

export default {
  name: "add-item",
  props: ["selectedItem", "itemName", "mediaType"],
  data() {
    return {
      item: {
        name: "",
      },
      snackbarText: "",
      snackbar: false,
      defaultImage: require("@/assets/wink-placeholder.png"),
      loading: false,
      file: "",
      progress: 0,
      fileSrc: "",
    };
  },
  computed: {
    ...mapState(["user", "userDoc", "groupId"]),
  },

  methods: {
    update() {
      if (this.item.name) {
        this.loading = true;

        db.collection("files")
          .doc(this.item[".key"])
          .update({
            name: this.item.name,
            description: this.item.description ? this.item.description : "",
          })
          .then((response) => {
            this.loading = false;
            this.$emit("success", "updated");
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc("add_item.empty_fields");
        this.snackbar = true;
      }
    },
    filePicker() {
      document.getElementById("file").click();
    },
    async save() {
      if (this.file && this.item.name) {
        this.loading = true;
        this.item.extension = this.file.type.split("/").pop();
        this.item.dataName = `${Math.round(
          Math.random() * (10000000000000 - 100000000) + 100000000
        )}_${Math.round(
          Math.random() * (10000000000000 - 100000000) + 100000000
        )}.${this.item.extension}`;
        this.item.createdAt = new Date();
        this.item.createdBy = this.user.uid;
        this.item.size = this.file.size;
        this.item.type = this.mediaType ? this.mediaType : "";
        this.item.groupId = this.groupId;
        this.item.description = this.item.description
          ? this.item.description
          : "";

        let reference = db.collection("files").doc();
        let url = await this.uploadFile(reference.id);
        let screenshot = "";

        if (this.mediaType == "image")
          screenshot = await this.uploadScreenshot(reference.id);
        else screenshot = await this.uploadVideoScreenshot(reference.id, url);

        this.item.screenshot = screenshot;
        this.item.url = url;

        // console.log(this.item);

        reference
          .set(this.item)
          .then((response) => {
            this.item.id = reference.id;
            this.$emit("success", this.item);
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          });
      } else {
        this.snackbarText = this.$tc("add_item.empty_fields");
        this.snackbar = true;
      }
    },
    uploadFile(fileId) {
      return new Promise((resolve, reject) => {
        var uploadTask = fb
          .app()
          .storage("gs://beanage-files")
          .ref()
          .child(`${this.groupId}/${fileId}/${this.item.dataName}`)
          .put(this.file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            // console.log("err");
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                // console.log(downloadURL);
                resolve(downloadURL);
              })
              .catch((err) => {
                // console.log(err);
              });
          }
        );
      });
    },

    uploadScreenshot(fileId) {
      return new Promise((resolve, reject) => {
        let image = document.getElementById("image");
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, canvas.width, image.clientHeight);
        var img_data = canvas.toDataURL("image/jpg");

        var uploadTask = fb
          .app()
          .storage("gs://beanage-files")
          .ref()
          .child(`${this.groupId}/${fileId}/file-screenshot.jpg`)
          .putString(img_data, "data_url");
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    },

    uploadVideoScreenshot(fileId, url) {
      return new Promise((resolve, reject) => {
        const videoToThumb = new VideoToThumb(this.file);
        videoToThumb
          .load()
          .positions([1])
          .type("base64")
          .error(function (err) {})
          .done((img) => {
            let url = img[0];
            var uploadTask = fb
              .app()
              .storage("gs://beanage-files")
              .ref()
              .child(`${this.groupId}/${fileId}/file-screenshot.jpg`)
              .putString(url, "data_url");
            uploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                this.loading = false;
                this.snackbarText = this.$tc("messages.unknown");
                this.snackbar = true;
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  // console.log(downloadURL);
                  resolve(downloadURL);
                });
              }
            );
          });
      });
    },

    selectedFile(e) {
      this.file = e;
      if (this.file) {
        let fileNameArray =
          this.file && this.file.name ? this.file.name.split(".") : "";

        if (fileNameArray && fileNameArray.length) {
          fileNameArray.pop();
          this.item.name = fileNameArray.join();
        }

        var reader = new FileReader();
        reader.onload = (e) => {
          this.fileSrc = e.target.result;
          if (this.mediaType == "video")
            document.getElementById("video").src = this.fileSrc;
        };
        reader.readAsDataURL(this.file);
      }
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.preview {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.image-container {
  width: 100%;
  margin-top: 5px;
  max-height: 235px;
  position: relative;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

#video {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>